<template> 
    <ul class="card" :style="{background:bg,color:fg}"> 
        <li class="slot" :style="{fontSize: size}"><slot name="prepend"></slot></li>
        <li v-for="(item,index) in list" :key="index" :style="{fontSize: size}">{{item}}</li>
        <li class="slot" :style="{fontSize: size}"><slot></slot></li>
    </ul>
</template>

<style scoped>
ul {
  margin: 5px 0 2px 0;
  text-align: center;
  display: inline-block;
}
li { 
  float: left;
  font-size: 18px; 
  text-align: center; 
}
.slot {
  padding: 0 5px;
  display: inline-block;
;
}
</style>

<script>
export default { 
    props:{
      number: {
        type: [String, Number],
        default: 0,
      },
      fg: {
        type: String,
        default: "#666",
      },
      bg: {
        type: String,
        default: "transparent",
      },
      size: String
    },
    data(){ 
        return{ 
            list: [], 
        } 
    }, 
    mounted(){ 
        this.scroll(); 
    }, 
    watch:{
        number(val, oldVal) {
          //console.log(oldVal, "->" ,val);
          this.scroll();
        },
    },
    methods:{ 
        scroll(){ 

          let arr = [];
          this.number.toString().split("").forEach((value) => {
            arr.push(value) 
          });
          
          let t1 = setInterval(() => {
            
            let l = arr.length;
            if (l == 0){
              clearInterval(t1);
              return;
            }

            let n = arr.pop();
            this.list[l-1] = n;
            if (n == ".") return ;

            let t2 = setInterval(() => {
              this.list[l-1]++;
              this.list[l-1] >= 10 && (this.list[l-1] = 0);
              this.list[l-1] == n && clearInterval(t2);
            }, 30);

          }, 100);

        }
    } 
}
</script>
