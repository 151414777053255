<template>
  <BaseLayout title="我的交易" :subtitle="subtitle" bg="linear-gradient(180deg, #06C, #FFF 60%, #FFF)" fg="#FFE" @click-more="onMore">
    <template #more>
      <van-icon name="question-o" size="large"/>
    </template>
    <template #extra>
      <van-button icon="setting" size="small" :style="{borderRadius:'10px 0 0 10px', opacity: 0.8}" color="#09F" @click="onExtra">设置</van-button>
    </template>

    <van-pull-refresh v-model="state.refreshing" @refresh="onChange">

      <van-row class="stat" gutter="2" justify="space-around" align="center">
        <van-col span="12">
          <div class="title"><van-tag size="large" type="success">今日盈利</van-tag></div> 
          <number-roll :number="state.stat.today_profit" fg="#F66" size="24px"><template #prepend>$</template></number-roll>
          <div class="remark">交易 {{state.stat.today_num}} 笔</div>
        </van-col>
        <van-col span="12">
          <div class="title"><van-tag size="large" type="success">累计盈利</van-tag></div>
          <number-roll :number="state.stat.all_profit" fg="#F66" size="24px"><template #prepend>$</template></number-roll>
          <div class="remark">交易 {{state.stat.all_num}} 笔</div>
        </van-col>
      </van-row>

      <van-grid class="toolbar" :border="true" :column-num="4">
        <van-grid-item icon="fire-o"           :style="{color: '#F09'}" text="交易动态" to="/my/recent" />
        <van-grid-item icon="font-o"           :style="{color: '#F90'}" text="策略模板" to="/my/strategy/modify" />
        <van-grid-item icon="cash-back-record" :style="{color: '#C6F'}" text="手动建仓" to="/my/strategy/apply" />
        <van-grid-item icon="after-sale"       :style="{color: '#69F'}" text="自动建仓" to="/my/plan" />
      </van-grid>

      <div class="list">
        <van-tabs v-model:active="state.active" type="card" color="#09F" offset-top="45" sticky swipeable animated @change="onChange">
          <van-tab v-for="(v,k) in {0:'进行中的交易',1:'已完成的交易'}" :key="k" :title="v" >
            <van-list v-model:loading="state.loading" :finished="state.finished" loading-text="正在请求数据 ..." finished-text="没有更多了" :immediate-check="false" @load="onLoad">
              <van-cell-group class="block" :border="false" v-for="(item,index) in state.list" :key="index" @click="onDetail(item)">
                <van-cell class="dt" :border="false" :title="item.ts" >
                  <template #extra><span :style="{color:item.color}">涨 {{item.ratio}} %，盈 {{item.profit}} U</span></template>
                </van-cell>
                <van-cell class="item" :border="false" icon-prefix="icon-symbol" :icon="item.symbol.toLowerCase()" :title="item.symbol.toUpperCase() + ' / USDT'" :value="'$' + item.amount"/>
                <div class="extend" :style="{color:'#333'}">于 {{item.dt.substr(5,11)}} {{item.pid > 0 ? "由方案 " + item.pid + " 自动" : "手动"}}建仓，共买入 {{item.times}} 次 {{item.hang}}</div>
                <div class="extend" v-show="item.status!=9">成本价：{{item.cost_price}}，当前价：{{item.price}}</div>
              </van-cell-group>
            </van-list>
          </van-tab>
        </van-tabs>
      </div>

    </van-pull-refresh>

    <van-popup v-model:show="state.popup" position="bottom" transition="van-slide-up" round closeable>
      <van-cell :border="false" icon-prefix="icon-symbol" :icon="state.item.symbol.toLowerCase()" :title="state.item.symbol.toUpperCase() + ' / USDT'" :label="'盈利额：' + state.detail.profit + ' U ，盈利率：' + (state.detail.ratio * 100).toString().replace(/(\.\d\d)(\d+)$/, '$1') + ' %'" @click="$copy(state.item.id, false)"/>
      <van-steps class="step" direction="vertical" :active="0" @dblclick="state.popup=false">
        <van-step class="status" v-show="state.item.status!=9">
          <p class="head">{{status}}</p>
          <p>成本价：{{state.detail.cost_price}}，当前价：{{state.detail.price}}</p>
          <p>补仓价：{{state.detail.fall_price}}，止盈价：{{state.detail.rise_price}}</p>
        </van-step>
        <van-step v-for="(log, i) in state.detail.log" :key="i">
          <p class="head">{{log.dt + " 「" + ["清仓", "建仓", "加仓", "补仓"][log.type] + "」"}}</p>
          <p>{{"数量：" + log.num}}</p>
          <p>{{"单价：" + log.price + "，总价：" + log.amount}}</p>
        </van-step>
      </van-steps>
      <van-action-bar>
        <van-action-bar-icon icon="after-sale" text="调整" v-show="state.item.status!=9" :to="'/my/strategy/adjust?id=' + state.item.id" />
        <van-action-bar-icon icon="refund-o" text="补仓" v-show="state.item.status!=9" @click="onOpt('append', '按照策略补仓')" />
        <van-action-bar-icon icon="cash-back-record" text="平仓" v-show="state.item.status!=9" @click="onOpt('clear', '平仓')" />
        <van-action-bar-button color="#090" text="再来一单" :to="'/my/strategy/apply?id=' + state.item.id"/>
      </van-action-bar>
    </van-popup>

    <van-dialog v-model:show="state.dialog" showCancelButton theme="round-button" confirmButtonText="设置" @confirm="onConfig">
      <div class="form">
        <label>火币API访问密钥：</label>
        <input type="text" placeholder="Access Key" v-model="state.config.ak" />
        <input type="test" placeholder="Secret Key" v-model="state.config.sk" />
        <label v-show="false">同时进行的最大任务数：</label>
        <van-slider v-show="false" v-model="state.config.num" active-color="#ee0a24">
          <template #button>
            <div class="slider-button">{{ state.config.num }}</div>
          </template>
        </van-slider>
      </div>
    </van-dialog>

  </BaseLayout>
</template>

<style scoped>
.stat {
  min-height: 120px;
  margin: 5px;
  background: #FFF;
  border:solid 1px #EEE;
  border-radius: 5px;
  text-align: center;
}
.stat .title {
  margin-bottom: 8px;
}
.stat .remark {
  font-size: 12px;
}

.toolbar {
  margin: 10px 5px;
  border: solid 1px #EEE;
  border-radius: 5px;
}

.list {
  margin: 10px 5px;
  min-height: 370px;
  padding-top: 20px;
  background: #FFF;
  border: solid 1px #EEE;
  border-radius: 5px;
}
.block {
  margin-top: 20px;
  padding-bottom: 10px;
  border: solid 1px #F9F9F9;
  border-radius: 10px;
}
.dt {
  height: 30px;
  padding-top: 2px;
  background:#F9F9F9;
  font-size: 12px;
}
.item {
  height: 40px;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}
.extend {
  margin-left: 55px;
  color: #999;
  font-size: 12px;
  line-height: 24px;
}

.step {
  margin-bottom: 60px;
}
.step p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 24px;
}
.step .head {
  font-size: 14px;
  font-weight: 600;
}

.form {
  margin: 5px 10px 30px 10px;
  height: 200px;
  display: flex;
  flex-flow: column nowrap;
  justify-content:space-around;
  align-items: left;
  font-size: 12px;
}
.form label {
  padding: 8px;
  background: #EEE;
  color: #666;
}
.form input {
  font-size: 14px;
  border-width: 0;
  border-bottom: solid 1px #EEE;
}
.slider-button {
  width: 26px;
  background-color: #ee0a24;
  border-radius: 100px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
}
</style>

<script>
import {reactive} from 'vue'
import BaseLayout from '@/components/BaseLayout.vue'
import NumberRoll from '@/components/NumberRoll.vue'

export default {
  components: { BaseLayout, NumberRoll },

  setup() {
    const state = reactive({
      popup: false,   // 详情弹出
      dialog: false,  // 配置弹出

      stat: {}, // 统计信息

      active: 0, // 当前激活的标签
      refreshing: false,
      loading: false,
      finished: false,

      list: [],   // 列表
      offset: 0,  // 记录偏移量
      limit : 20, // 每页记录数量

      id: 0,      // 选中的记录ID
      item: {},   // 选中的记录内容
      detail: {}, // 选中的记录详情

      config: { // 配置信息
        num: 2
      },
    });
    return { 
      state
    };
  },

  created() {
    this.onChange();
  },

  computed: {
    subtitle(){
      return "正在进行中交易共有 " + (typeof this.state.stat.working != "undefined" ? this.state.stat.working : 0) + " 笔";
    },
    status(){
      let obj = {
        0: "监控中",
        1: "正在交易",
        2: "正在判断补仓",
        3: "正在判断止盈",
        4: "调整中",
        5: "正在实时监控",
        6: "交易失败（请检查账户余额）",
        7: "超标挂起（只清仓不补仓）",
        8: "手动暂停",
        9: "交易完成"
      };
      return typeof obj[this.state.detail.status] != "undefined" ? obj[this.state.detail.status] : "未知";
    }
  },

  methods: {
    onMore() {
      console.log("Click more ...");
    },
    onExtra() {
      this.$toast.loading();
      this.$http.get("/exchange/setup").then((data) => {
        this.state.config = {...this.state.config, ...data};
        this.state.dialog = true;
        this.$toast.clear();
      });
    },
    onConfig() {
      this.$http.post("/exchange/setup?config=" + JSON.stringify(this.state.config));
    },

    onChange() {
      this.state.loading = true;
      this.state.finished = false;
      this.state.offset = 0;
      this.state.list = [];
      this.onLoad();
      this.$http.get("/exchange/summary?mine=1").then((data) => this.state.stat = data);
    },
    onLoad() {
      this.$http.get("/exchange/tasklist?mine=1&finished=" + this.state.active + "&offset=" + this.state.offset + "&limit=" + this.state.limit).then((data) => {
        this.state.refreshing = false;
        if (data.length == 0 || data.length < this.state.limit)
          this.state.finished = true;
        this.state.loading = false;
        this.state.offset += data.length;

        for (let i in data){
          let item = data[i];
          this.state.list.push({...item, ...{
            symbol: item.symbol.replace(/usdt$/, ""),
            cost  : item.cost.replace(/(\.\d\d)(\d+)$/, "$1"),
            amount: item.amount.replace(/(\.\d\d)(\d+)$/, "$1"),
            profit: item.profit.replace(/(\.\d\d)(\d+)$/, "$1"),
            ratio : (parseFloat(item.ratio)*100).toString().replace(/(\.\d\d)(\d+)$/, "$1"),
            color : (item.ratio > 0 ? "#090" : "#F66"),
            hang  : ((item.status == 7 || item.status == 8) ? "℗" : ""),
          }});
        }
      });
    },
    onDetail(item) {
      this.state.item = item;
      this.state.id = 0;
      this.state.detail = {};
      this.$http.post("/exchange/taskdetail?id=" + item.id).then((data) => {
        this.state.id = item.id;
        this.state.detail = data;
        this.state.popup = true;
      });
    },
    onOpt(action, name){
      this.$dialog.confirm({
        message: "确认要" + name + "吗?",
        theme: "round-button",
      }).then(() => {
          this.$http.post("/exchange/tasksave?id=" + this.state.id + "&action=" + action).then((data) => {
            this.onDetail(this.state.item);
          });
      });
    },
  }
}
</script>
